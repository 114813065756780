import React, { useEffect, useRef, useState } from "react";
import { Table, TableColumnType, TableProps } from "antd";
import { Checkbox, Popover } from "antd";
import {
  FaAt,
  FaCalendar,
  FaLeaf,
  FaPaperclip,
  FaPaperPlane,
  FaPeopleGroup,
  FaPlus,
} from "react-icons/fa6";
import { MdClose, MdEmail } from "react-icons/md";
import { IoIosPaperPlane, IoMdEye, IoMdPaperPlane } from "react-icons/io";
import ProfileModal from "../Modals/ProfileModal";
import { useDispatch, useSelector } from "react-redux";
import { ownerSelector, prospectsSelector, RootState } from "../../store/store";
import { RxAvatar, RxPaperPlane } from "react-icons/rx";
import {
  setCurrentProspect,
  setProspects,
  setProspectsCount,
  setSelectedProspects,
} from "../../store/slices/prospectsSlice";
import ProspectsSettings from "../Modals/ProspectsSettings";
import { Prospect, ProspectTableColumns } from "../../types";
import { BsLinkedin } from "react-icons/bs";
import Tags from "../Dropdowns/Tags";
import { removeTagFromProspect } from "../../store/features/ownerActions";
import { fetchProspects } from "../../utils";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import { LINKEDIN_URLS } from "../../constants";

type ProspectTableProps = {
  listChanged?: boolean;
};

const ProspectTable: React.FC<ProspectTableProps> = ({
  listChanged,
}: ProspectTableProps) => {
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const { prospects, prospectsCount, selectedProspects, inCampaign, listName } =
    useSelector(prospectsSelector);
  const [pageSize, setPageSize] = useState(10);
  const { listId, owner } = useSelector(ownerSelector);
  const [hoverOnTag, setHoverOnTag] = useState("");
  const [filteredProspects, setFilteredProspects] =
    useState<Prospect[]>(prospects);
  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showTags, setShowTags] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isFetchingAll, setIsFetchingAll] = useState(false);
  // const [allSelected, setAllSelected] = useState(false);
  const [selectedProspectsArray, setSelectedProspectsArray] = useState<
    string[]
  >([]);
  const [gapFromTop, setGapFromTop] = useState("");
  // const [isTagAdded, setIsTagAdded] = useState(false);
  const tagModalSelfRef = useRef<HTMLDivElement>(null);
  const modalSelfRef = useRef<HTMLDivElement>(null);

  const handleProspectFetch = async (page: number, limit: number) => {
    const loader = toast.loading("Fetching prospects...");
    try {
      const getProspects = await fetchProspects(
        owner?._id as string,
        listId,
        page,
        limit
      );
      if (getProspects.prospects.length === 0) {
        toast.dismiss(loader);
        return toast.error("No prospects found");
      }

      toast.dismiss(loader);
      dispatch(setProspects(getProspects.prospects));
      return getProspects.prospects;
    } catch (error) {
      toast.dismiss(loader);
      toast.error("Failed to fetch prospects");
      return [];
    }
  };

  const handleRemoveTagFromProspect = async (
    tagId: string,
    prospectId: string
  ) => {
    try {
      const prospectIds = [prospectId];

      const res = await dispatch(removeTagFromProspect({ prospectIds, tagId }));
      if (res.meta.requestStatus === "fulfilled") {
        if (res.payload.success) {
          const list = await fetchProspects(
            owner?._id as string,
            listId,
            1,
            10
          );
          dispatch(setProspects(list.prospects));
          dispatch(setProspectsCount(list.totalProspects));
          toast.success(res.payload.message);
        } else if (!res.payload.success) {
          toast.error(res.payload.message);
        }
      } else {
        toast.error("Error adding tag to prospect");
      }
    } catch (error: any) {
      console.log(error);
      toast.error("Error adding tag to prospect");
    }
  };

  const handleSelectAll = async (selected: boolean) => {
    if (selected && prospectsCount > prospects.length) {
      setIsFetchingAll(true);
      const loader = toast.loading("Fetching all prospects...");

      try {
        // Fetch all prospects in one go
        const allProspects = await fetchProspects(
          owner?._id as string,
          listId,
          1,
          prospectsCount
        );

        if (allProspects.prospects.length > 0) {
          dispatch(setProspects(allProspects.prospects));
          setFilteredProspects(allProspects.prospects);
          setSelectedProspectsArray(
            allProspects.prospects.map((p: any) => p._id)
          );
        }

        toast.dismiss(loader);
      } catch (error) {
        toast.dismiss(loader);
        toast.error("Failed to fetch all prospects");
      }

      setIsFetchingAll(false);
    } else {
      // If deselecting all or if all prospects are already loaded
      setSelectedProspectsArray(selected ? prospects.map((p) => p._id) : []);
    }

    setIsAllSelected(selected);
  };

  const columns: TableColumnType<ProspectTableColumns>[] = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Company/Job",
      dataIndex: "job",
    },
    {
      title: "Statuses",
      dataIndex: "statuses",
      filterMode: "tree",
      filterSearch: true,

      filterDropdown: (
        <div className="p-4 flex flex-col">
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions([...filterOptions, "ACCEPTED"]);
              } else {
                setFilterOptions((prev) =>
                  prev.filter((item) => item !== "ACCEPTED")
                );
              }
            }}
          >
            Connected
          </Checkbox>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions([...filterOptions, "PENDING", "NOT_STARTED"]);
              } else {
                setFilterOptions((prev) =>
                  prev.filter(
                    (item) => item !== "PENDING" && item !== "NOT_STARTED"
                  )
                );
              }
            }}
          >
            Not Connected
          </Checkbox>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions([...filterOptions, "HAS_EMAIL"]);
              } else {
                setFilterOptions(
                  filterOptions.filter((item) => item !== "HAS_EMAIL")
                );
              }
            }}
          >
            Has email
          </Checkbox>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions([...filterOptions, "IS_PREMIUM"]);
              } else {
                setFilterOptions(
                  filterOptions.filter((item) => item !== "IS_PREMIUM")
                );
              }
            }}
          >
            Is premium
          </Checkbox>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      filterDropdown: (
        <div className="p-4 flex flex-col">
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions([...filterOptions, "INVITATION_SENT"]);
              } else {
                setFilterOptions((prev) =>
                  prev.filter((item) => item !== "INVITATION_SENT")
                );
              }
            }}
          >
            Invitation sent
          </Checkbox>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions([...filterOptions, "PROFILE_VISITED"]);
              } else {
                setFilterOptions((prev) =>
                  prev.filter((item) => item !== "PROFILE_VISITED")
                );
              }
            }}
          >
            Profile Visited
          </Checkbox>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions([...filterOptions, "MESSAGE_SENT"]);
              } else {
                setFilterOptions(
                  filterOptions.filter((item) => item !== "MESSAGE_SENT")
                );
              }
            }}
          >
            Message sent
          </Checkbox>
        </div>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
    },
  ];

  const tableData: ProspectTableColumns[] = filteredProspects.map(
    (prospect) => {
      return {
        key: prospect._id,
        name: (
          <div
            onClick={() => {
              dispatch(setCurrentProspect(prospect));
              setShowProfileModal(true);
            }}
            className="flex font-primary  items-center gap-2 cursor-pointer"
          >
            {prospect.image_url ? (
              <img
                src={prospect.image_url}
                alt=""
                className="w-8 h-8 rounded-full"
              />
            ) : (
              <RxAvatar
                className="w-10 h-10 text-mainColor cursor-pointer"
                // size={20}
              />
            )}
            <p className="whitespace-nowrap text-xs">
              {prospect.full_name?.length > 20 ? (
                <Popover content={prospect.full_name}>
                  {prospect.full_name.slice(0, 20) + "..."}
                </Popover>
              ) : (
                prospect.full_name
              )}
            </p>
          </div>
        ),
        job: (
          <div
            onClick={() => {
              dispatch(setCurrentProspect(prospect));
              setShowProfileModal(true);
            }}
            className="flex gap-2 font-primary"
          >
            {prospect.current_company_name && prospect.title ? (
              <p className="text-xs">
                {prospect.title} at {prospect.current_company_name}
              </p>
            ) : (
              <p className="text-xs">{prospect.headline}</p>
            )}
          </div>
        ),
        statuses: (
          <div
            onClick={() => {
              dispatch(setCurrentProspect(prospect));
              setShowProfileModal(true);
            }}
            className="flex gap-2"
          >
            <Popover
              content={
                prospect.connected === "ACCEPTED"
                  ? "Connected"
                  : "Not Connected"
              }
            >
              <FaPaperclip
                className={`${
                  prospect.connected === "ACCEPTED"
                    ? "text-mainColor cursor-pointer"
                    : "text-gray-400 cursor-pointer"
                } `}
                size={15}
              />
            </Popover>

            <Popover
              content={
                prospect.sameGroup
                  ? "In the same group"
                  : "Not in the same group"
              }
            >
              <FaPeopleGroup
                className={`${
                  prospect.sameGroup ? "text-mainColor" : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
            <Popover
              content={
                prospect.sameEvent
                  ? "In the same event"
                  : "Not in the same event"
              }
            >
              <FaCalendar
                className={`${
                  prospect.sameEvent ? "text-mainColor" : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
            <Popover
              content={
                prospect.contactInfo?.emailAddress
                  ? "Has email address"
                  : "No email address"
              }
            >
              <FaAt
                className={`${
                  prospect.contactInfo?.emailAddress
                    ? "text-mainColor"
                    : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
            <Popover
              content={
                prospect.isPremium ? "Is a premium user" : "Not a premium user"
              }
            >
              <BsLinkedin
                className={`${
                  prospect.isPremium ? "text-amber-500/70" : "text-gray-400 "
                } cursor-pointer`}
                size={15}
              />
            </Popover>
          </div>
        ),
        actions: (
          <div className="flex gap-2">
            <Popover
              content={
                prospect.connected !== "NOT_STARTED"
                  ? "Invitation Sent"
                  : "No Invitation Sent"
              }
            >
              <MdEmail
                className={`${
                  prospect.connected !== "NOT_STARTED"
                    ? "text-mainColor"
                    : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
            {/* <Popover content="No follow sent">
          <MdRssFeed
            className="text-gray-400 cursor-pointer"
            size={20}
          />
        </Popover> */}
            <Popover
              content={
                prospect.profileVisited
                  ? "Profile Visited"
                  : "Profile not visited"
              }
            >
              <IoMdEye
                className={`${
                  prospect.profileVisited ? "text-mainColor" : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
            {/* <Popover content="No message sent">
        <MdMailOutline
          className="text-gray-400 cursor-pointer"
          size={20}
        />
      </Popover> */}
            {/* <Popover content="No Message request sent">
          <MdMessage
            className="text-gray-400 cursor-pointer"
            size={20}
          />
        </Popover> */}
            <Popover
              content={
                prospect.messageSent ? "Message Sent" : "No message sent"
              }
            >
              <IoIosPaperPlane
                className={`${
                  prospect.messageSent ? "text-mainColor" : "text-gray-400"
                } cursor-pointer`}
                size={15}
              />
            </Popover>
          </div>
        ),
        tags: (
          <div className="relative flex gap-1 justify-center items-center">
            {prospect.tags.length > 0 ? (
              prospect.tags.length > 1 ? (
                <Popover
                  content={
                    <div className="flex flex-col gap-2">
                      {prospect.tags.map((tag) => (
                        <div key={tag._id} className="flex items-center gap-2">
                          <p
                            style={{ backgroundColor: tag.color }}
                            className={`text-xs py-0.5 px-2 cursor-pointer whitespace-nowrap rounded-xl text-white`}
                          >
                            {tag.name}
                          </p>
                          <MdClose
                            onClick={() =>
                              handleRemoveTagFromProspect(tag._id, prospect._id)
                            }
                            className="text-gray-400 cursor-pointer"
                            size={15}
                          />
                        </div>
                      ))}
                    </div>
                  }
                >
                  <div
                    onMouseEnter={() => setHoverOnTag(prospect._id)}
                    onMouseLeave={() => setHoverOnTag("")}
                    className="flex gap-2 items-center"
                  >
                    <p className="text-xs text-gray-400">
                      +{prospect.tags.length}
                    </p>
                    <FaPaperclip
                      className="text-mainColor cursor-pointer"
                      size={15}
                    />
                  </div>
                </Popover>
              ) : (
                <div className="flex items-center gap-2">
                  <p
                    style={{ backgroundColor: prospect.tags[0].color }}
                    className={`text-xs py-0.5 px-2 cursor-pointer rounded-xl text-white whitespace-nowrap`}
                  >
                    {prospect.tags[0].name}
                  </p>
                  <MdClose
                    onClick={() =>
                      handleRemoveTagFromProspect(
                        prospect.tags[0]._id,
                        prospect._id
                      )
                    }
                    className="text-gray-400 cursor-pointer"
                    size={15}
                  />
                </div>
              )
            ) : (
              <p className="text-xs p-1 whitespace-nowrap text-center bg-gray-500/50 text-white rounded-md">
                No tags
              </p>
            )}
            <span
              onClick={() =>
                setShowTags((prev) => {
                  if (prev === prospect._id) {
                    return "";
                  }
                  return prospect._id;
                })
              }
            >
              <FaPlus className="text-mainColor cursor-pointer" size={15} />
            </span>
            {showTags === prospect._id && (
              <Tags
                tagModalSelfRef={tagModalSelfRef}
                prospectId={prospect._id}
                listId={listId}
                onClose={() => setShowTags("")}
              />
            )}
          </div>
        ),
      };
    }
  );

  const tableProps = {
    columns,
    dataSource: tableData,
    pagination: {
      position: ["bottomRight"],
      total: prospectsCount,
      showSizeChanger: false,
      pageSize: pageSize,
      size: "small",
      onChange: async (page: number, pageSize: number) => {
        if (!isFetchingAll) {
          // Prevent pagination while fetching all
          await handleProspectFetch(page, pageSize);
        }
      },
    },
    rowSelection:
      !inCampaign && listName !== "All Prospects"
        ? {
            type: "checkbox",
            selectedRowKeys: selectedProspectsArray,
            onChange: (
              selectedRowKeys: React.Key[],
              selectedRows: ProspectTableColumns[]
            ) => {
              setSelectedProspectsArray(selectedRowKeys as string[]);
            },
            onSelectAll: handleSelectAll,
            selections: [
              {
                key: "all",
                text: `Select all ${prospectsCount} prospects`,
                onSelect: () => handleSelectAll(true),
              },
              {
                key: "none",
                text: "Clear selection",
                onSelect: () => handleSelectAll(false),
              },
            ],
          }
        : undefined,
    loading: isFetchingAll,
  };
  useEffect(() => {
    setPageSize(10);
    setFilterOptions([]);
  }, [listId]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        tagModalSelfRef.current &&
        !tagModalSelfRef.current.contains(e.target as Node)
      ) {
        setShowTags("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    setFilteredProspects(prospects);
    dispatch(setSelectedProspects(selectedProspectsArray));
    const table = document.getElementById("prospects");
    const positionY = table?.getBoundingClientRect().top;
    setGapFromTop(String(positionY));
    table?.style.setProperty("max-height", `calc(100vh - ${positionY}px)`);

    const handleFilter = async () => {
      // const loading = toast.loading("Filtering prospects...");
      if (filterOptions.length === 0) {
        setFilteredProspects(prospects);
        setPageSize(10);
        // toast.dismiss(loading);
        return;
      }

      const listForFilter = await fetchProspects(
        owner?._id as string,
        listId,
        1,
        prospectsCount
      );
      setPageSize(prospectsCount);
      const prospectsForFilter: Prospect[] = listForFilter.prospects;
      if (filterOptions.includes("ACCEPTED")) {
        const filteredProspects = prospectsForFilter.filter(
          (prospect) => prospect.connected === "ACCEPTED"
        );
        setFilteredProspects(filteredProspects);
      }
      if (
        filterOptions.includes("PENDING") ||
        filterOptions.includes("NOT_STARTED")
      ) {
        const filteredProspects = prospectsForFilter.filter(
          (prospect) => prospect.connected !== "ACCEPTED"
        );
        setFilteredProspects(filteredProspects);
      }
      if (filterOptions.includes("HAS_EMAIL")) {
        const filteredProspects = prospectsForFilter.filter(
          (prospect) => prospect.contactInfo.emailAddress
        );
        setFilteredProspects(filteredProspects);
      }
      if (filterOptions.includes("IS_PREMIUM")) {
        const filteredProspects = prospectsForFilter.filter(
          (prospect) => prospect.isPremium
        );
        setFilteredProspects(filteredProspects);
      }
      if (filterOptions.includes("INVITATION_SENT")) {
        const filteredProspects = prospectsForFilter.filter(
          (prospect) =>
            prospect.connected === "PENDING" ||
            prospect.connected === "ACCEPTED"
        );
        setFilteredProspects(filteredProspects);
      }

      if (filterOptions.includes("PROFILE_VISITED")) {
        const filteredProspects = prospectsForFilter.filter(
          (prospect) => prospect.profileVisited
        );
        setFilteredProspects(filteredProspects);
      }

      if (filterOptions.includes("MESSAGE_SENT")) {
        const filteredProspects = prospectsForFilter.filter(
          (prospect) => prospect.messageSent
        );
        setFilteredProspects(filteredProspects);
      }

      // toast.dismiss(loading);
    };

    handleFilter();

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      setPageSize(10);
    };
  }, [selectedProspectsArray, gapFromTop, prospects, filterOptions]);

  return (
    <>
      {prospects.length > 0 ? (
        <div
          id="prospects"
          className={`overflow-x-auto prospects pb-4  relative  overflow-y-scroll slimScrollbar`}
        >
          <Table
            id="prospects"
            className="text-xs font-primary "
            columns={columns}
            dataSource={tableData}
            pagination={{
              position: ["bottomRight"],
              total: prospectsCount,
              showSizeChanger: false,
              pageSize: pageSize,
              size: "small",
              onChange: async (page: number, pageSize: number) => {
                if (!isFetchingAll) {
                  // Prevent pagination while fetching all
                  await handleProspectFetch(page, pageSize);
                }
              },
            }}
            rowSelection={
              !inCampaign && listName !== "All Prospects"
                ? {
                    type: "checkbox",
                    selectedRowKeys: selectedProspectsArray,
                    onChange: (
                      selectedRowKeys: React.Key[],
                      selectedRows: ProspectTableColumns[]
                    ) => {
                      setSelectedProspectsArray(selectedRowKeys as string[]);
                    },
                    onSelectAll: handleSelectAll,
                    selections: [
                      {
                        key: "all",
                        text: `Select all ${prospectsCount} prospects`,
                        onSelect: () => handleSelectAll(true),
                      },
                      {
                        key: "none",
                        text: "Clear selection",
                        onSelect: () => handleSelectAll(false),
                      },
                    ],
                  }
                : undefined
            }
            loading={isFetchingAll}
          />
        </div>
      ) : (
        <div className="flex  mt-24 items-center gap-4 flex-col r h-full">
          <FaLeaf size={50} className="text-mainColor " />
          <p className="text-gray-700 text-xl font-semibold">
            Looks like you don't have any prospects yet
          </p>

          <div className="relative">
            <button
              onClick={() => setShowDropdown(!showDropdown)}
              className="bg-mainColor flex items-center shadow-md gap-2 font-semibold text-white text-xs p-2 px-4 rounded-md"
            >
              <FaPlus className="text-white" size={15} />
              <p>Import</p>
            </button>
            {showDropdown && (
              <div
                ref={modalSelfRef}
                className="absolute flex flex-col gap-2 top-full bg-white p-4 rounded-md -left-14 mt-2 shadow-lg z-50 text-xs whitespace-nowrap "
              >
                <Link
                  target="_blank"
                  to={LINKEDIN_URLS.searchUrl}
                  className="flex gap-2  p-2 font-semibold rounded-md text-black"
                >
                  <FaPlus className="" size={15} />
                  <p>Import via Linkedin Search</p>
                </Link>
                <Link
                  target="_blank"
                  to={LINKEDIN_URLS.connectionUrl}
                  className="flex gap-2  p-2 font-semibold rounded-md text-black"
                >
                  <FaPlus className="" size={15} />
                  <p>Import your connections</p>
                </Link>
                <Link
                  target="_blank"
                  to={LINKEDIN_URLS.groupUrl}
                  className="flex gap-2  p-2 font-semibold rounded-md text-black"
                >
                  <FaPlus className="" size={15} />
                  <p>Import from a Group</p>
                </Link>
                <Link
                  target="_blank"
                  to={LINKEDIN_URLS.eventUrl}
                  className="flex gap-2  p-2 font-semibold rounded-md text-black"
                >
                  <FaPlus className="" size={15} />
                  <p>Import from an Event</p>
                </Link>
                {/* <button
                  onClick={() => {
                    // setShowFileModal(true);
                    setShowDropdown(false);
                  }}
                  className="flex gap-2  p-2 font-semibold rounded-md text-black"
                >
                  <FaPlus className="" size={15} />
                  <p>Import from CSV</p>
                </button> */}
              </div>
            )}
          </div>
        </div>
      )}

      {showProfileModal && (
        <ProfileModal onClose={() => setShowProfileModal(false)} />
      )}
      {selectedProspects.length > 0 && (
        <ProspectsSettings setSelectedArray={setSelectedProspectsArray} />
      )}
    </>
  );
};

export default ProspectTable;
