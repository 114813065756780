import { Popover, Table, TableColumnType } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaLeaf, FaSpinner } from "react-icons/fa6";
import { ProspectTableColumns } from "../../types";
import { deleteAllRequests, getRequestsList } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { dashboardSelector, ownerSelector, RootState } from "../../store/store";
import { RxAvatar } from "react-icons/rx";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { getAllRequests } from "../../store/features/dashboardActions";

type Props = {
  onClose: () => void;
};

const PendingRequestsModal = (props: Props) => {
  const { owner } = useSelector(ownerSelector);
  const { linkedinData } = useSelector(dashboardSelector);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState("");
  const [noOfRequests, setNoOfRequests] = useState(0);
  const pendingModalRef = useRef<HTMLDivElement>(null);
  const [pendingConnections, setPendingConnections] = useState<any>([]);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        pendingModalRef.current &&
        !pendingModalRef.current.contains(e.target as Node)
      ) {
        props.onClose();
      }
    };
    const handleRequestsFetch = async () => {
      try {
        setLoading(true);
        const res = await getRequestsList(
          owner?._id as string,
          0,
          linkedinData?.numOfPendingRequests
        );
        let pendingRequests: any[] = res.data?.elements.map(
          (connection: any) => {
            return {
              connectionId:
                connection?.invitation?.entityUrn?.split("fsd_invitation:")[1],
              connectionName: connection?.title?.text || "No name",
              jobTitle: connection?.subtitle?.text || "No job title",
              imageUrl:
                connection?.invitation?.inviteeMemberResolutionResult
                  ?.profilePicture?.displayImageReferenceResolutionResult
                  ?.vectorImage["rootUrl"] +
                "" +
                connection?.invitation?.inviteeMemberResolutionResult?.profilePicture?.displayImageReferenceResolutionResult?.vectorImage?.artifacts?.splice(
                  -1
                )[0]?.fileIdentifyingUrlPathSegment,
              reqSentAt: connection?.sentTimeLabel,
            };
          }
        );
        // pendingRequests = pendingRequests?.reverse();
        setPendingConnections(pendingRequests);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    handleRequestsFetch();

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  const handlePendingRequestWithdraw = async (type: string, id?: string) => {
    var loadingToast = toast.loading("Withdrawing request");
    try {
      if (type === "SINGLE") {
        console.log("Single request", id);
        //Withdraw single request
        const connectionIds = [id as string];

        const res = await deleteAllRequests(
          owner?._id as string,
          connectionIds
        );
        if (res.data?.success) {
          const updatedPendingConnections = pendingConnections.filter(
            (prospect: any) => prospect?.connectionId !== connectionIds[0]
          );
          setPendingConnections(updatedPendingConnections);
          toast.dismiss(loadingToast);
          toast.success("Request withdrawn successfully");
          await dispatch(
            getAllRequests({ ownerId: owner?._id as string, type: "SENT" })
          );
        }
      } else if (type === "MULTIPLE") {
        //Withdraw multiple requests
        if (noOfRequests === 0) {
          toast.dismiss(loadingToast);
          toast.error("Invalid number of requests");
          return;
        }

        if (noOfRequests > pendingConnections.length) {
          toast.dismiss(loadingToast);
          toast.error("Number of requests exceeds pending requests");
          return;
        }
        // console.log("Multiple requests", noOfRequests);
        const connectionIds: any = [];
        const updatedArray = pendingConnections.slice(-noOfRequests);
        for (let i = 0; i < updatedArray?.length; i++) {
          // console.log(updatedArray[i]?.connectionName);
          connectionIds.push(updatedArray[i]?.connectionId);
        }
        // console.log(connectionIds);
        const res = await deleteAllRequests(
          owner?._id as string,
          connectionIds
        );
        if (res.data?.success) {
          setPendingConnections(
            pendingConnections.filter(
              (prospect: any) => !connectionIds.includes(prospect?.connectionId)
            )
          );
          setNoOfRequests(0);
          toast.dismiss(loadingToast);
          toast.success("Requests withdrawn successfully");
          await dispatch(
            getAllRequests({ ownerId: owner?._id as string, type: "SENT" })
          );
        }
      } else if (type === "ALL") {
        //Withdraw all requests
        const connectionIds = pendingConnections.map(
          (prospect: any) => prospect?.connectionId
        );
        const res = await deleteAllRequests(
          owner?._id as string,
          connectionIds
        );
        if (res.data?.success) {
          setPendingConnections([]);
          toast.dismiss(loadingToast);
          toast.success("Requests withdrawn successfully");
          await dispatch(
            getAllRequests({ ownerId: owner?._id as string, type: "SENT" })
          );
        }
      } else if (type === "TIME") {
        if (time === "") {
          toast.dismiss(loadingToast);
          toast.error("Invalid time");
          return;
        }

        if (time === "Sent today") {
          const connectionIds = pendingConnections
            .filter((prospect: any) => prospect?.reqSentAt === "Sent today")
            .map((prospect: any) => prospect?.connectionId);

          if (connectionIds.length === 0) {
            toast.dismiss(loadingToast);
            toast.error("No requests sent today");
            return;
          }
          const res = await deleteAllRequests(
            owner?._id as string,
            connectionIds
          );

          if (res.data?.success) {
            setPendingConnections(
              pendingConnections.filter(
                (prospect: any) =>
                  !connectionIds.includes(prospect?.connectionId)
              )
            );
            toast.dismiss(loadingToast);
            toast.success("Requests withdrawn successfully");
            await dispatch(
              getAllRequests({ ownerId: owner?._id as string, type: "SENT" })
            );
          }
        } else if (time === "Sent 7 days ago") {
          //  all requests sent from today to 7 days ago
          const filters = [
            "Sent today",
            "Sent yesterday",
            "Sent 2 days ago",
            "Sent 3 days ago",
            "Sent 4 days ago",
            "Sent 5 days ago",
            "Sent 6 days ago",
            "Sent 7 days ago",
          ];
          const filteredConns = pendingConnections.filter((prospect: any) =>
            filters.includes(prospect?.reqSentAt)
          );
          const connectionIds = filteredConns.map(
            (prospect: any) => prospect?.connectionId
          );

          if (connectionIds.length === 0) {
            toast.dismiss(loadingToast);
            toast.error("No requests sent in the last 7 days");
            return;
          }

          const res = await deleteAllRequests(
            owner?._id as string,
            connectionIds
          );

          if (res.data?.success) {
            setPendingConnections(
              pendingConnections.filter(
                (prospect: any) =>
                  !connectionIds.includes(prospect?.connectionId)
              )
            );
            toast.dismiss(loadingToast);
            toast.success("Requests withdrawn successfully");
            await dispatch(
              getAllRequests({ ownerId: owner?._id as string, type: "SENT" })
            );
          }
        }
      }

      toast.dismiss(loadingToast);
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error("Error withdrawing request");
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
    },
    {
      title: "Request Sent",
      dataIndex: "requestSent",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const tableData = pendingConnections.map((prospect: any) => {
    return {
      key: prospect?.connectionId,
      name: (
        <div className="flex font-primary  items-center gap-2 cursor-pointer">
          {prospect?.imagUrl !== "" ? (
            <img
              src={prospect?.imageUrl}
              alt=""
              className="w-8 h-8 rounded-full"
            />
          ) : (
            <RxAvatar
              className="w-10 h-10 text-mainColor cursor-pointer"
              // size={20}
            />
          )}
          <p className="whitespace-nowrap text-xs">
            {prospect?.connectionName.length > 20 ? (
              <Popover content={prospect?.connectionName}>
                {prospect?.connectionName.slice(0, 20) + "..."}
              </Popover>
            ) : (
              prospect?.connectionName
            )}
          </p>
        </div>
      ),
      jobTitle: <div>{prospect?.jobTitle.substring(0, 50) + "..."}</div>,
      requestSent: <div>{prospect?.reqSentAt}</div>,
      action: (
        <div className="flex gap-2">
          <button
            onClick={() => {
              handlePendingRequestWithdraw("SINGLE", prospect?.connectionId);
            }}
            className="text-xs text-white bg-red-500 px-2 py-1 rounded-md"
          >
            Withdraw
          </button>
        </div>
      ),
    };
  });
  return (
    <div className="absolute top-0 left-0 z-50 w-full h-screen backdrop-blur-sm bg-black/20">
      <div
        ref={pendingModalRef}
        className="bg-white w-[80%] h-[80%] text-xs absolute top-1/2 left-1/2 transform  -translate-x-1/2 -translate-y-1/2 rounded-lg p-4"
      >
        {pendingConnections.length > 0 ? (
          <div
            id="prospects"
            className={`overflow-x-auto prospects pb-4  relative  overflow-y-scroll slimScrollbar h-full text-sm`}
          >
            <div className="flex w-full justify-between items-center p-2 bg-slate-300">
              <h1>Actions</h1>

              <div className="flex items-center justify-center gap-4">
                <p>Enter no of requests</p>
                <input
                  value={noOfRequests}
                  onChange={(e) => {
                    setNoOfRequests(parseInt(e.target.value));
                  }}
                  type="number"
                  placeholder="0"
                  className="border border-gray-300 rounded-md p-1 w-20"
                />
                <button
                  onClick={() => {
                    handlePendingRequestWithdraw("MULTIPLE");
                  }}
                  className="text-xs text-white bg-red-500 px-2 py-1 rounded-md"
                >
                  Withdraw
                </button>
              </div>
              <div className="flex gap-4 items-center justify-center">
                <p>Withdraw by time:</p>
                <select
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                  name="time"
                  id="time"
                  className="border border-gray-300 rounded-md p-1"
                >
                  <option value="Sent today">Today</option>
                  <option value="Sent 7 days ago">7 days</option>
                  {/* <option value="sent 30 days ago">30 days</option> */}
                </select>
                <button
                  onClick={() => {
                    handlePendingRequestWithdraw("TIME");
                  }}
                  className="text-xs text-white bg-red-500 px-2 py-1 rounded-md"
                >
                  Withdraw
                </button>
              </div>
              <button
                onClick={() => {
                  handlePendingRequestWithdraw("ALL");
                }}
                className="text-xs text-white bg-red-500 px-2 py-1 rounded-md"
              >
                Withdraw All
              </button>
            </div>
            <Table
              id="prospects"
              className="text-xs font-primary "
              columns={columns}
              dataSource={tableData}
              pagination={false}
              //   pagination={{
              //     position: ["bottomRight"],
              //     total: prospectsCount,
              //     showSizeChanger: false,
              //     pageSize: pageSize,
              //     size: "small",
              //     onChange: async (page, pageSize) => {
              //       await handleProspectFetch(page, pageSize);
              //     },
              //   }}
              //   rowSelection={
              //     !inCampaign && listName !== "All Prospects"
              //       ? {
              //           type: "checkbox",
              //           onChange: (selectedRowKeys, selectedRows) => {
              //             setSelectedProspectsArray(selectedRowKeys as string[]);
              //           },
              //         }
              //       : undefined
              //   }
            />
            {/* <button
              onClick={() => {
                props.onClose();
              }}
              className="p-2 absolute bottom-0 bg-red-500 text-white rounded-md"
            >
              Close
            </button> */}
          </div>
        ) : (
          <div className="flex justify-center gap-4 flex-col r h-full items-center">
            {!loading ? (
              <>
                <FaLeaf size={50} className="text-mainColor " />
                <p className="text-gray-700 text-xl font-semibold">
                  Looks like you don't have any pending requests
                </p>
              </>
            ) : (
              <>
                <FaSpinner size={50} className="text-mainColor animate-spin" />
                <p>Fetching pending requests</p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingRequestsModal;
